import { isMobile } from "react-device-detect";
import { copyToClipboard } from "./utils";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { API_URL, DOWNLOAD_URL } from "./urls";

export function useDownload() {
  const [searchParams] = useSearchParams();

  const adjustId = searchParams.get("adjustId") || "1gdir1x2";
  const campaign = searchParams.get("campaign");
  const adgroup = searchParams.get("adgroup");
  const creative = searchParams.get("creative");
  const fbclid = searchParams.get("fbclid");
  const fb_pixel_id = searchParams.get("fb_dynamic_pixel");

  const onDownload = async () => {
    const inviteCode = searchParams.get("invite");

    console.log({ adjustId, campaign, adgroup, creative, fbclid });

    if (inviteCode) {
      copyToClipboard(inviteCode as string);
    }

    if (!isMobile) {
      return "popup";
    } else {
      window.open(DOWNLOAD_URL, "_blank");
      return;
      // let newWindow: Window | null = null;
      // newWindow = window.open("", "_blank");
      // const apiUrl = API_URL;
      // let downloadUrl = null;
      // try {
      //   const response = await axios.get(apiUrl);
      //   downloadUrl = response.data.link_jump;
      // } catch (error) {
      //   console.error(error);
      // }
      // if (!downloadUrl) {
      //   if (newWindow) {
      //     newWindow.close();
      //   }
      //   return;
      // }
      // const encodedUrl = encodeURIComponent(downloadUrl);
      // if (fbclid) {
      //   const params = {
      //     campaign,
      //     adgroup,
      //     creative,
      //     fbclid,
      //     fb_pixel_id
      //   };
      //   const queryString = Object.keys(params)
      //     .filter(
      //       (key) =>
      //         params[key as keyof typeof params] !== null &&
      //         params[key as keyof typeof params] !== undefined
      //     )
      //     .map(
      //       (key) =>
      //         `${key}=${encodeURIComponent(
      //           params[key as keyof typeof params] as string
      //         )}`
      //     )
      //     .join("&");

      //   // Modify the URL of the newly opened window
      //   if (newWindow) {
      //     newWindow.location.href = `https://app.adjust.com/${adjustId}?${queryString}&redirect=${encodedUrl}`;
      //   }
      // } else {
      //   if (newWindow) {
      //     newWindow.location.href = downloadUrl;
      //   }
      // }
    }
  };

  return { onDownload };
}
